Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.addkeywordAPiEndPoint = "bx_block_pdfkeywordhighlighter2/create_keywords";
exports.allKeyworeds = "bx_block_pdfkeywordhighlighter2/keywords"
exports.removeKeyworeds = "bx_block_pdfkeywordhighlighter2/delete_keywords"
exports.uploadFile = "bx_block_pdfkeywordhighlighter2/upload_document"
exports.postAPiMethod = "POST";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Keyword";
exports.txtInputPlaceholder = "Enter Keyword";
exports.labelTitleText = "pdfkeywordhighlighter2";
exports.labelBodyText = "Search your keywords in pdf";
exports.getFileUrl = "bx_block_pdfkeywordhighlighter2/show_all_documents";
exports.deleteDocumentUrl="bx_block_pdfkeywordhighlighter2/remove_document"
exports.uploadButtonText="+ UPLOAD"
exports.webUrlConfig='https://brock-276470-ruby.b276470.stage.eastus.az.svc.builder.ai/PdfKeywordHighlighter2?token='


exports.btnAddKeyword = "ADD";
exports.btnChoosePDF = "CHOOSE PDF";
exports.btnRemovePDF = "REMOVE PDF";
exports.btnExampleTitle = "APPLY";
exports.btnViewPDF = "VIEW PDF";
exports.btnDownloadPDF = "DOWNLOAD PDF";

// Customizable Area End