import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
// Customizable Area Start
const PenImg = require("../assets/image_writ.png")
const CancelImg = require("../../global_assets/CancelImg.png");
// Customizable Area End

import MainScreenController, {
  Props,
} from "./MainScreenController.web";
import FileAttachmentWeb from "../../FileAttachment/src/FileAttachment.web";
import TopLogoLogout from "./TopLogoLogout.web";

export default class MainScreen extends MainScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{height: "100%", overflowX:"hidden", background: "rgb(8, 11, 39)"}}>
          <Box style={{height: "100%"}}>
            <TopLogoLogout navigation={this.props.navigation} id={""} 
              KeywordAddData = {this.state.KeywordAddData?.length}  
              docCount={this.state.docCount} 
              clearDoc={ this.clearDoc }
            />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between", height: "80%"}}>
              <div style={webStyle.mainWrapper}>
                <div style={webStyle.mainWrapper_child}>
                  <TextField
                    data-test-id="addKeywords"
                    keyword-id='keyword'
                    style={webStyle.textField}
                    fullWidth
                    type="text"
                    name="text"
                    value={this.state.text}
                    onChange={this.handleTextChange}
                    onKeyPress={this.handleKeywordAddition}
                    variant="outlined"
                    InputProps={{
                      style: {
                        height: '45px',
                        borderRadius: '11px',
                        outline: 'none'
                      },
                      placeholder: 'Enter Keywords to search',
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={PenImg} style={webStyle.pen_img} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box display='flex' flexWrap='wrap' mt={2}>
                    {this.state.KeywordAddData.map((item: any, index: any) => {
                      return (
                        <>
                          {item?.attributes?.name &&
                            <Box p={1} key={item.id} style={webStyle.keyword_Add_box}>
                              <Typography variant='subtitle2' style={{ fontSize: '14px' }}>{item?.attributes?.name}</Typography>
                              <img src={CancelImg}
                                data-test-id="remove"
                                style={webStyle.keyword_remove_img}
                                onMouseOver={(e) => {e.currentTarget.style.opacity = "0.5"}}
                                 onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                                onClick={() => {

                                  let x = this.state.KeywordAddData
                                  x[index].attributes.name = !x[index].attributes.name
                                  this.setState({ KeywordAddData: [...x] })
                                  this.handleKeywordRemove(item.id)
                                }
                                }
                              />
                            </Box>
                          }
                        </>

                      )
                    })}
                  </Box>
                  <div style={webStyle.uploaddiv}>
                    <FileAttachmentWeb id={""} navigation={undefined} 
                      uploadedFileCount={this.handleCount}
                      clearFiles = {this.state.clear}
                      resetClearState = { (e:any) => this.setState({ clear: false}) }
                    />
                  </div>
                </div>
              </div>
              <div style={{ textAlign: 'center', backgroundColor: '#000A3C'}}>
                <Box py={2} height={48} >
                  <Button disabled={!(this.state.KeywordAddData?.length && this.state.docCount)} onClick={() => this.props.navigation.navigate('DocumentOpener')} variant="contained"
                   style={!(this.state.KeywordAddData?.length && this.state.docCount) ? webStyle.applyButton_disabled : webStyle.applyButton}>
                    apply</Button>
                </Box>
              </div>
            </div>
          </Box>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    backgroundColor: '#080B27',
    marginBottom: '0px',
    // minHeight: '72vh',
    // maxHeight: '120vh'
  },
  mainWrapper_child: {
    marginLeft: '52px',
    marginRight: '52px',
  },
  textField: {
    marginTop: '-28px',
    backgroundColor: 'white',
    borderRadius: '11px'
  },
  pen_img: {
    width: '22px',
    height: '24px'
  },
  no_Btn: {
    backgroundColor: '#7990FF',
    color: 'white',
    width: '114px'
  },
  keyword_Add_box: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#e9e4f8',
    border: '1px solid #8762f3',
    borderRadius: '6px',
    minWidth: '114px',
    marginRight: '20px',
    marginBottom: '10px'
  },
  keyword_remove_img: {
    marginLeft: '14px',
    marginRight: '1px',
    height: '3vh',
    cursor:'pointer'
  },
  uploaddiv: {
    height: 'auto',
    paddingTop: '20px',
    // paddingBottom:'60px'
  },
  applyButton: {
    backgroundColor: '#FFD300',
    color: 'black',
    width: '300px',
  },
  applyButton_disabled: {
    backgroundColor: '#938028',
    color: 'black',
    width: '300px',
  },
};
// Customizable Area End
