import {
  Box,
  Button
} from "@material-ui/core";
import React, { useCallback, useEffect, useState, useRef } from "react";
{/* @ts-ignore */ }
import { Document, Page, pdfjs, TextItem } from "react-pdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface CustomDocsProps {
  link: string;
  keywordList: any[];
  allKeywords: any[];
  unmatchedKeaword: any;
  indexes: any,
  // documentRef: any,
  setChildRef: any,
  fileName: string,
}

const CustomDocViewer = ({ link, keywordList, allKeywords, unmatchedKeaword, indexes, setChildRef, fileName }: CustomDocsProps) => {

  const [numPages, setNumPages] = useState(null);
  const [textItems, setTextItems] = useState<TextItem[]>();
  const [stringsToHighlight, setStringsToHighlight] = useState<any[]>([]);
  const [keywords, setKeywords] = useState<any[]>([]);

  useEffect(() => {
    setKeywords(keywordList.filter((item: any) => item.checked))
  }, [keywordList]);

  useEffect(() => {
    setStringsToHighlight(keywordList.filter((item: any) => item.checked));
  }, [keywords, indexes]);

  const onPageLoadSuccess = useCallback(async (page) => {
    const textContent = await page.getTextContent();
    setTextItems(textContent.items);

    const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
    textLayers.forEach(layer => {
      const { style } = layer as HTMLElement;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });

  }, []);

  
  const [matchedKeywords, setMatchedKeywords] = useState<Set<string>>(new Set());
  const [unmatchedKeywords, setUnmatchedKeywords] = useState<string[]>([]);

  const customTextRenderer = useCallback(
    (textItem: any) => {
      if (!textItems) {
        return null;
      }

      const { str } = textItem;

      // Iterate through all selected keywords
      for (let i = 0; i < stringsToHighlight.length; i++) {
        const stringToHighlight = stringsToHighlight[i].name.toLowerCase().trim();
        const escapedWord = stringToHighlight.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        const regex = new RegExp(escapedWord, 'gi');

        // Check if the keyword is found in the text
        if (regex.test(str)) {
          setMatchedKeywords((prevSet) => new Set(prevSet.add(stringToHighlight)));
        }
      }

      // Highlight keywords using dangerouslySetInnerHTML
      const highlightedStr = stringsToHighlight.reduce((acc, keyword) => {
        const regex = new RegExp(keyword.name, 'gi');
        return acc.replace(regex, `<mark style="background-color: ${keyword.keyword_color}; opacity: 0.5; color: transparent;">$&</mark>`);
      }, str);

      return <div dangerouslySetInnerHTML={{ __html: highlightedStr }} />;
    },
    [stringsToHighlight, textItems]
  );

  useEffect(() => {
    // Convert matchedKeywords set to an array
    const matchedKeywordsArray = Array.from(matchedKeywords);
    // Compare with all keywords to find unmatched keywords
    const updatedUnmatchedKeywords = allKeywords // Filter out non-string elements
    .map(keyword => keyword.name.toLowerCase().trim())
    .filter(keyword => !matchedKeywordsArray.includes(keyword));
    
    // Set the unmatched keywords in the state
    setUnmatchedKeywords(updatedUnmatchedKeywords);
    }, [matchedKeywords, allKeywords]);
    
    useEffect(() => {
      const filterUnmatched = unmatchedKeywords.filter(keyword => !matchedKeywords.has(keyword));
      unmatchedKeaword(filterUnmatched);
    }, [unmatchedKeywords]);    
  
  const canvasRef = useRef<any>(null); // Use 'any' type for the ref

  //@ts-ignore
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    const documentObject= {
      numPages: numPages,
      fileName: fileName,
      canvasRef: canvasRef.current,
      documentRef:  document
    };
    console.log('Document', documentObject, "document ::", document);
  
    setChildRef(documentObject);
    // setChildRef(canvasRef.current);
  }

  // const handleExportPDF = async () => {
  //   const pdf = new jsPDF();
  //   if (numPages) {
  //     for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
  //       const canvas = await html2canvas(documentRef.current, {
  //         useCORS: true,
  //         scrollX: 0,
  //         scrollY: -window.scrollY,
  //         scale: 2,
  //         windowWidth: document.documentElement.offsetWidth,
  //         windowHeight: document.documentElement.offsetHeight,
  //         logging: false,
  //       });

  //       if (pageNumber > 1) {
  //         pdf.addPage();
  //       }

  //       const imgData = canvas.toDataURL("image/jpeg", 2.0);
  //       pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
  //     }
  //     pdf.save("highlighted_document.pdf");
  //   }
  // };


  // const handleExportPDF = async () => {
  //   if (!canvasRef.current || !numPages) {
  //     return;
  //   }
  
  //   const pdf = new jsPDF();
  
  //   for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
  //     const canvas = await html2canvas(canvasRef.current, {
  //       useCORS: true,
  //       scale: 3,
  //       scrollX: 0,
  //       scrollY: -window.scrollY,
  //       logging: false,
  //     });
  
  //     if (pageNumber > 1) {
  //       pdf.addPage();
  //     }
  
  //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     const imgProps = pdf.getImageProperties(imgData);
  
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
  //     //pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight, "", "FAST");

  //   }
  
  //   pdf.save("highlighted_document.pdf");
  // };

  // const handleExportPDF = async () => {
  //   const canvas = await html2canvas(canvasRef.current, {
  //     useCORS: true,
  //     scrollX: 0,
  //     scrollY: window.scrollY,
  //     scale: 2,
  //   });
  
  //   const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const imgWidth = 210; // A4 width in mm
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
  //   pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
  //   pdf.save("highlighted_document.pdf");
  // };
 
 
  const handleExportPDF = async () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const totalPages = numPages || 0;
  
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      const pageElement = document.querySelector(`.react-pdf__Page[data-page-number="${pageNumber}"]`) as HTMLElement;
      if (!pageElement) continue; // Skip if page element not found
  
      console.log("Page Element",pageElement);
      const canvas = await html2canvas(pageElement, {
        useCORS: true,
        scale: 2, //2
      });
  
      if (pageNumber > 1) {
        pdf.addPage();
      }
  
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
    }
  
    pdf.save("highlighted_document.pdf");
  };


  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'center', height: "32em", overflowY: "scroll", overflowX: "hidden", width: "100%" }}>
        <Document
          renderMode={"canvas"}
          inputRef={(ref1) => {
            const inputRef = ref1 as any;
            //documentRef.current = inputRef;
            canvasRef.current = inputRef
          }}
          file={link} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Box key={index + 1} style={{ marginBottom: "7px" }}>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                onLoadSuccess={onPageLoadSuccess}
                // @ts-ignore
                customTextRenderer={customTextRenderer}
              />
            </Box>
          ))}
        </Document>
      </Box>
    </>
  )
}
export default React.memo(CustomDocViewer);
