import React from "react";

// Customizable Area Start
import { Box, Button, Typography, Tab, Tabs, AppBar, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
export const CrossIcon = require("../assets/delete-button.png");
import Grid from "@material-ui/core/Grid"
// import { Document, Page} from "react-pdf";

import Loader from "../../../components/src/Loader.web";

import Pdfkeywordhighlighter2Controller, {
  Props,
  configJSON,
} from "./Pdfkeywordhighlighter2Controller";

const { Document, Page } = require('react-pdf'); 
 

interface IFilesData{
  file_name: string;
  url: string;
  id: string;

}
interface IEvent{
  target:{
    value:string,
    files:[{}]
  }
}

interface IKeywordArray {
  id: number;
  name: string;
  border_color: string;
  keyword_color: string;
  checked: boolean;
}

interface IChildRefRefArray extends React.HTMLProps<HTMLDivElement> {
 
  fileName:string,
  numPages:number,
  canvasRef:{
    querySelector(selector: string): Element | null,
  },
  documentRef: Document | null;
}

export default class Pdfkeywordhighlighter2 extends Pdfkeywordhighlighter2Controller {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  

  renderKeywordList = (item:IKeywordArray)=> {
    return (
      <React.Fragment key={item.id}>
        {item &&
          <Box p={1} key={item.id} style={{
            ...webStyle.keyword_Add_box, borderStyle: 'solid', borderWidth: '2px',
            borderColor: `${item.keyword_color}`, background: (item.border_color)
          }}
          >
            <Box display='flex' alignItems='center' width={'inherit'}>
              
              <Typography
                data-test-id="keyword"
                variant='subtitle2'
                style={{ fontSize: '16px', wordBreak: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 'inherit' }}
              >
                {item.name}
              </Typography>
            </Box>
            {this.renderKeywordRemoveBtn(item)} 
          </Box>
        }
      </React.Fragment>
    )
  }

  renderKeywordRemoveBtn = (item: IKeywordArray)=>{
    return(
      <svg xmlns="http://www.w3.org/2000/svg"

              data-test-id="handleWord_remove"
              onClick={this.handleKeywordRemove.bind(this,item.id)
              }
              viewBox="0 0 24 24" width="18" height="18" style={{ ...webStyle.keyword_remove_img, fill: item.keyword_color }}>
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
            </svg>
    );
  }

  renderFilesList = (files:IFilesData, pdfNuberId:number) =>{
    return (
      <Tab
        data-test-id="change-tabes-inner"
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {this.removeExtension(files.file_name)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-test-id='removePdf'
              onClick={this.handleDocumentRemove.bind(this,files.id)}
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{
                marginLeft: '5px',
                fill: 'whitesmoke',
                cursor: 'pointer'
              }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
            </svg>
          </Box>
        }
        key={pdfNuberId}
        onClick={this.setIndexValue.bind(this,pdfNuberId)}
        style={{
          color: '#fff',
          fontSize: 13,
          minHeight: 43,
          margin: 'auto',
          marginTop: '7px',
          padding: '0.25rem'
        }}
      />
    );
  }

  renderPdfView = (link:string, numPages:number) =>{

    return (
      <Box style={{ display: "flex", justifyContent: "center", height: "32em", overflowY: "scroll", overflowX: "hidden", width: "100%" }}>
        <Document
          data-test-id="document"
          renderMode={"canvas"}
          inputRef={this.inputRefCallback} 
          file={link}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages || 0), (elValue, pdfNuberId) => (
            <Box key={pdfNuberId + 1} style={{ marginBottom: "7px" }}>
              <Page
                data-test-id="document-pages"
                key={`page_${pdfNuberId + 1}`}
                pageNumber={pdfNuberId + 1}
                onLoadSuccess={this.onPageLoadSuccess}
                customTextRenderer={this.customTextRenderer}
              />
            </Box>
          ))}
        </Document>
      </Box>
    );
  }

  // Customizable Area End
  render() {

return (
  // Customizable Area Start

  <>
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }} style={{ display:`${this.state.orientation=='portrait'?'block':'flex'}`, padding: `${this.state.orientation=='portrait'?'10px':'2rem'}`, justifyContent: 'space-around', fontFamily: 'Roboto Mono' }}>
        { this.state.orientation !== 'portrait' && <Box style={{height:`${this.state.orientation=='portrait'?'auto':"calc(40em * 1.0325)"}` , display: 'flex', flexDirection: 'column', backgroundColor: 'white', width:`${this.state.orientation=='portrait'?'100%':'23%'}`, borderRadius: '0.75rem' }}>
          <Box style={{
            flex: this.state.allKeywords.length > 0 ? 2 : 1, marginBottom: '12px',
            borderTopLeftRadius: '0.75rem',
            borderTopRightRadius: '0.75rem',
            borderBottom: '1px dashed gray',
            paddingBottom: '25px',
          }} >
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.75rem',}}>
              <TextField variant="standard" margin="normal" required id="keywordSearch"
                data-test-id="keyword_search"
                name="keywordSearch"
                autoFocus
                placeholder={configJSON.txtInputPlaceholder}
                value={this.state.keywordText}
                onChange={this.handleTextChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  disableUnderline: true,
                }}
                style={{ boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)', padding: '10px', borderRadius: '15px' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                data-test-id="apply_keyword"
                variant="contained"
                style={webStyle.applyButton}
                onClick={() => this.handleKeywordAddition()}
              >
                apply
              </Button>
            </div>
          </Box>
          {(this.state.allKeywords.length > 0) && <Box style={{
            overflow: 'auto',
            overflowX: 'hidden',
            borderBottom: '1px dashed gray',
            flex: 5
          }}>
            <Box mx={3.6} display='flex' flexWrap='wrap' justifyContent='center' alignItems='center'>
              <Box style={webStyle.select_all}>
                <Typography>All Keywords</Typography>
                
              </Box>
              {this.state.allKeywords.map((item: IKeywordArray) => {
                  return this.renderKeywordList(item)
              })}
            </Box>
          </Box>
          }
          
        </Box>}
        <Box style={{ fontFamily: 'Roboto Mono',marginTop:'2rem', marginRight:`${this.state.orientation=='portrait'?'0':'3rem'}`, marginLeft: `${this.state.orientation=='portrait'?'0':'1.3rem'}`, borderRadius: '0.75rem', width:`${this.state.orientation=='portrait'?'100%':'63%'}`,position: 'relative' }}>
          <Grid container spacing={2} style={{ marginBottom: '5px' }}>
            <Grid item xs={8}>
              <AppBar position="static" style={{ background: "#333545", minHeight: 53, borderRadius: '0.25rem' }}>
                <Tabs value={this.state.value}
                  data-test-id="change-tabes"
                  variant="scrollable" scrollButtons="auto" indicatorColor="primary"
                  style={{ minHeight: 53 }} aria-label="scrollable tabs">
                  {
                    this.state.responseData && this.state.responseData.map((files, pdfNuberId) => {
                      return this.renderFilesList(files, pdfNuberId)
                    })}
                </Tabs>
              </AppBar>
            </Grid>
           { this.state.orientation !== 'portrait' && <Grid item xs={4}>
              <Button style={{ width: '100%', backgroundColor: '#7700A6', color: 'white', height: '53px', fontSize: '0.9rem' }} variant="contained" component="label" data-test-id='Uploadbtn'>
               {configJSON.uploadButtonText}
                <input type="file" data-test-id="multi-file"  accept='application/pdf' onChange={this.onFileChange}  hidden />
              </Button>
            </Grid>}
          </Grid>
          <Box sx={webStyle.mainWrapper}>
            {(this.state.responseData.length === 0) && <Box style={{ height: '50px' }}>
              No Documents Found
            </Box>
            }
            {this.state.responseData.filter(() => {
              return true;
            }).map((file, pdfNuberId:number) => {
              return (
                <Box key={pdfNuberId} style={{ width: 'fit-content',overflowX:'scroll', borderRadius: '0.75rem', overflow: pdfNuberId === this.state.pdfDocId ? "" : "hidden", height: pdfNuberId === this.state.pdfDocId ? "auto" : "0" }}>
                  {file.url && (
                    this.renderPdfView(file.url, this.state.numPages)
                  )}
                </Box>
              )
            })
            }
            {this.state.responseData.length !== 0 &&
              <Button
                style={{ fontFamily: 'Roboto Mono', width: '100%', marginTop: '15px', marginBottom: '15px', backgroundColor: '#B5E9FF', color: 'black', height: '53px', fontSize: '0.9rem' }}
                variant="contained" component="label"
                onClick={this.handleDownloadPdf}
                data-test-id='Downloadbtn' 
              >
                <strong>Download</strong>
              </Button>
            }
              <Box>
          <Loader
          loading={this.state.loading} />
         </Box>
            
          </Box>
        </Box>
      </Box>
      
    </ThemeProvider>
  </>

  // Customizable Area End
);
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto Mono",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    justifyContent: 'center'
  },
  buttonStyle: {
    height: "2.813rem",
    marginTop: 10,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  applyButton: {
    backgroundColor: '#7700A6',
    color: 'white',
    width: '119px',
    fontSize: '18px',
  },
  cross_img: {
    height: "5vh",
    cursor: 'pointer'
  },
  select_all: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '8%',
    marginRight: '3%',
  },
  keywords_container: {
    height: '50%',
    overflow: 'auto',
    overflowX: 'hidden',
    borderBottom: '1px dashed gray',
    borderTop: '1px dashed gray'
  },
  keyword_Add_box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#e9e4f8',
    border: '1px solid #8762f3',
    borderRadius: '6px',
    width: '100%',
    height: '35px',
    margin: '10px'
  },
  keyword_remove_img: {
    marginRight: '1px',
    height: '3vh',
    cursor: 'pointer'
  },
  no_Btn: {
    backgroundColor: "#7700A6",
    color: "white",
    width: "114px",
    fontFamily: "Roboto Mono",
  },
  loaderBox: {
"& .makeStyles-circularContainer-16": {
  paddingLeft:'15% !important'
},
  }
  // Customizable Area End

};

// Customizable Area End