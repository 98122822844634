import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { ChangeEvent, ReactNode } from 'react';
import { Platform } from "react-native";

import { setStorageData } from "../../../framework/src/Utilities";


import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const { pdfjs, Page, TextItem } = require('react-pdf'); 
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { WebViewMessageEvent } from 'react-native-webview';
import DocumentPicker from "react-native-document-picker";

interface WebViewMessageData {
  action: string; 
}

export interface TextItemNew {
  str: string;
  dir: string;
  transform: number[];
  width: number;
  height: number;
  fontName: string;
  hasEOL: boolean;
}

export interface PDFPageItem {
  getTextContent: (...args: []) => {items:TextItemNew[]};
}

interface IKeywordResponse {
  data:[{
      id: number;
      name: string;
      border_color: string;
      keyword_color: string;
  }]
}

interface IKeywordArray {
  id: number;
  name: string;
  border_color: string;
  keyword_color: string;
  checked: boolean;
}
 
interface imageType{
  name:string;
  uri:string|Blob;
  type:string;
}

interface IResponseData{
  file_name: string;
  url: string;
  id: string;
  data:{
    id:string,
    attributes:{
      documents:[]
    }
  },
  errors:"documents not found"
}
interface IChildRefRefArray extends React.HTMLProps<HTMLDivElement> {
 
  fileName:string,
  numPages:number,
  canvasRef:{
    querySelector(selector: string): Element | null,
  },
  documentRef: Document | null;
  
}


interface IEvent{
  target:{
    value:string,
    files:[{}]
  }
}



interface IFromData{

}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  keywordText: string,
  allKeywords: Array<IKeywordArray>,
  unMatchedKeyword: Array<IKeywordArray>,
  onlyKeywords: Array<IKeywordArray>,
  loading:boolean,
  firstIntialLoad:boolean,
  token:string,
  value:string,  
  responseData: Array<IResponseData>,
  pdfValidationPopUp:boolean,
  checkedKeyword: boolean,
  childRefRefArray: Array<IChildRefRefArray>,
  orientation:string,
  pdfDocId:number,

  pdfChooseStatus: boolean,
  webUrl: string,

  fileName: string;
  numPages: number;
  textItems: TextItemNew[];
  stringsToHighlight: Array<IKeywordArray>;
  keywords: Array<IKeywordArray>;
  mValue: number;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Pdfkeywordhighlighter2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiId: string = ""
  postApiId: string = ""
  getAllKeywordsApiId: string = ""
  keywordRemoveCallId: string = ""
  keywordAddId: string = ""
  removeDocId: string = ""

  public canvasRef: React.RefObject<HTMLCanvasElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      value:'0',
      orientation:'',
      pdfValidationPopUp:false,
      loading:false,
      childRefRefArray:[],
      keywordText:'',
      responseData:[],
      firstIntialLoad:true,
      pdfDocId:0,
      onlyKeywords:[],
      allKeywords: [],
      unMatchedKeyword: [],
      checkedKeyword: false,
      pdfChooseStatus: false,
      webUrl: configJSON.webUrlConfig,

      fileName: "",
      numPages: 0,
      textItems: [],
      stringsToHighlight: [],
      keywords: [],
      mValue: 0,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.canvasRef = React.createRef<HTMLCanvasElement>();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.setState({
      loading:false
    })
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getApiId) {
        this.handleGetApi(responseJson);
      }
      if (apiRequestCallId === this.postApiId) {
        this.handlePostApi(responseJson);
      }
      if (apiRequestCallId === this.getAllKeywordsApiId) {
        
        this.handleKeywordsResponse(responseJson);
      }
      if ((apiRequestCallId === this.keywordRemoveCallId) || (apiRequestCallId === this.keywordAddId)) {
        
        this.getAllKeywords();
      }
      if (apiRequestCallId === this.removeDocId) {
        this.setState({ pdfDocId: 0 })
        window.location.reload()
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (textValue: string) => {
      this.setState({ txtInputValue: textValue });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgValue = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgValue.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgValue);
  }

  // web events
  setInputValue = (textValue: string) => {
    this.setState({ txtInputValue: textValue });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();

    if(Platform.OS === 'web') {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenValue = urlParams?.get('token') || ' ';
      this.setState({token:tokenValue},()=>{
        this.getAllKeywords();
      })
      this.setScreenOrientation()
    }else{  
      const tokenValue = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzMwLCJleHAiOjE2OTMzNzUyMDksInRva2VuX3R5cGUiOiJsb2dpbiJ9.hqArnIi_OFXhic9JlMWaB699Bb6D8Po9184hE8MHaRaUPSUEE3QU4ka-AAkZcYx9OMBnTfbIKd4jdOOcg5anJg";
      this.setState({token:tokenValue},()=>{
        this.getUploadedFile();
        this.getAllKeywords();
      })
    }
  }

  handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ keywordText: event.target.value });
  };

  handleGetApi = (responseJson: IResponseData) => {
    
    if(responseJson.errors ==='documents not found'){
      return
    }
    else if (responseJson) {
      this.setState({ 
        responseData: responseJson.data.attributes.documents,
      })
      this.updateKeywords();
    } 
  }

  handlePostApi = (responseJson: IResponseData) => {
    this.setState({ responseData: responseJson.data.attributes.documents })
  }

  handleKeywordAddition = () => {

    const textValue = this.state.keywordText;

    let tokenValue = this.state.token;

    this.setState({ keywordText: '' });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const attrsValue = {
      name: [textValue]
    };
    const attrValue = {
      type: "keyword",
      attributes: attrsValue,
    };
    const httpBody = {
      data: attrValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.keywordAddId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addkeywordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true
    })

    return true;

  }

  handleKeywordRemove = (itemValue: number) => {
    let removeToken = this.state.token
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": removeToken
    };

    const attrValue = {
    };
    const httpBody = {
      data: attrValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.keywordRemoveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeKeyworeds + "?id=" + itemValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading:true
    })

    return true;
  }

  handleKeywordsResponse = (responseJson: IKeywordResponse) => {
    if (responseJson.data) {
      this.getUploadedFile();
    }
    
    if (responseJson) {
      if (this.state.firstIntialLoad) {
        const tempAllKeywords = responseJson.data.map((item: { id: number; name: string; border_color: string, keyword_color: string; }) => {
          return {
            id: item.id,
            name: item.name,
            border_color: item.border_color,
            keyword_color: item.keyword_color,
            checked: true
          }
        });
        this.setState({ allKeywords: tempAllKeywords, firstIntialLoad: false })
        this.setState({ checkedKeyword: tempAllKeywords.every((elValue: { checked: boolean; }) => elValue.checked) });
        this.setState(prev => { this.setState({ onlyKeywords: prev.allKeywords }) })
        return
      }
      const tempAllKeywords = responseJson.data.map((item: { id: number; name: string; border_color: string, keyword_color: string; }) => {
        const itemCheck = this.state.allKeywords.find(element => element.id === item.id)?.checked;
        return {
          id: item.id,
          name: item.name,
          border_color: item.border_color,
          keyword_color: item.keyword_color,
          checked: Boolean(itemCheck === undefined ? true : itemCheck)

    }
  });
  this.setState({ allKeywords: tempAllKeywords })
  this.setState({ checkedKeyword: tempAllKeywords.every((element: { checked: boolean; }) => element.checked) });
  this.setState(prev => { this.setState({ onlyKeywords: prev.allKeywords }) })

  
  window.location.reload()
}
  }

  getAllKeywords = () => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllKeywordsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allKeyworeds
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading:true
    })

    return true;
  };

  removeExtension = (fileName: string) => {
    const nameCheck = fileName.substring(0, fileName.lastIndexOf('.'));
    return nameCheck.length > 15 ? nameCheck.substring(0, 15) : nameCheck;
  }

  handleDocumentRemove = (itemValue: string) => {
    let removeToken =this.state.token
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": removeToken
    };
    const attrsValue = {
      id: itemValue
    };
    const attrValue = {
      attributes: attrsValue,
    };
    const httpBody = {
      data: attrValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeDocId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteDocumentUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    this.setState({ pdfValidationPopUp: true });
  
    // const pdfFileSend = event.target.files?.[0]; // Use optional chaining here
    const pdfFileSend = null;
    if (pdfFileSend) {
      formData.append('data[documents][]', pdfFileSend);
      this.onUploadFileAPICall(formData);
    }
  };

  onUploadFileAPICall = (formData: IFromData) => {

    const header = {
      token:this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading:true
    })

    return true;
  };

  getUploadedFile = () => {
    const header = {
      token:this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFileUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading:true
    })

    return true;
  };

  setScreenOrientation = () => {

    if (window.matchMedia("(orientation: portrait)").matches) {
      this.setState({
       orientation:'portrait'
      });
    }   
  }

  handleChildRef = (refranceData: IChildRefRefArray) => {
    this.setState({
      childRefRefArray: [...this.state.childRefRefArray, refranceData]
    })
  };

  setIndexValue = (pdfNuberId:number) => {
    this.setState({ pdfDocId: pdfNuberId });
  }

  handleDownloadPdf = ()=> {
   this.handleExportPDF(this.state.orientation) 
  } 
  
  
  handleExportPDF = async ( orientation: string) => {
   
    const childRef:IChildRefRefArray = this.state.childRefRefArray[0];
    const { numPages, fileName, canvasRef } = childRef;
    const pdfData = new jsPDF("p", "mm", "a4");
    const totalPages = numPages || 0;
    
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      const pageElement = canvasRef.querySelector(`.react-pdf__Page[data-page-number="${pageNumber}"]`) as HTMLElement;
      if (!pageElement) continue; 

      const canvas = await html2canvas(pageElement,
        {
          useCORS: true,
          scale: 2,

        });

      if (pageNumber > 1) {
        pdfData.addPage();
      }
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = pdfData.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdfData.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");
    }

    const saveAs = `${fileName}.pdf`;
    if (orientation !== 'portrait') {
      return pdfData.save(saveAs);

    } else {
      const pdfDataUri = pdfData.output('datauristring');
      const pdfUrl = window.location.href;
      const pdfNewUrl = pdfUrl + '&pdfdata=test';
      setStorageData("pdfData", pdfDataUri);
      window.location.href = pdfNewUrl;
    }

  };
  
  handleUnMatchedKeaword = (keywordValue: IKeywordArray[]) => {
    this.setState({ unMatchedKeyword: keywordValue })
  }

  updateKeywords() {
    const  keywordList  = this.state.allKeywords;
    const keywords = keywordList.filter((item) => item.checked);
    this.setState({ keywords, stringsToHighlight: keywords });

    this.setState({ 
      fileName: this.state.responseData[0].file_name,
    })
    
  }

  inputRefCallback = (refData: HTMLCanvasElement) => {
    // this.canvasRef.current = refData;
  }

  onPageLoadSuccess = async (pageNumbers: PDFPageItem) => {
    const textContent = await pageNumbers.getTextContent();

    this.setState({ textItems: textContent.items });
    const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
    

    textLayers.forEach((layer) => {
      // const {style}:{top:string, left:string, transform:string} = layer;
      const { style } = layer as HTMLElement; // Use type assertion her
      style.top = "0";
      style.left = "-3";
      style.transform = "";
    });
  };

  highlightPattern(fullString: string, pattern: string): ReactNode[] {
    const { keywords } = this.state;
    const regex = new RegExp(`(${pattern})`);
    const parts = pattern?.split(" ").length > 1 ? fullString.split(regex) : fullString.split(" ");
    const keywordValue: string[] = [];

    parts.forEach((partValue) => {
      const filtered = keywords.filter((elemValue) => {
        return elemValue.name.toLowerCase().replace(/[\.,]/g, "") === partValue.toLowerCase().replace(/[\.,]/g, "");
      });

      if (filtered.length > 0) {
        keywordValue.push(partValue.toLowerCase().replace(/[\.,]/g, ""));
      }
    });

    return parts.map((partValue, pdfNuberId) => {
      const trimmedPart = partValue.trim();
      const filtered = keywords.filter(
        (elemValue) => elemValue.name.toLowerCase().replace(/[\.,]/g, "") === trimmedPart.toLowerCase().replace(/[\.,]/g, "")
      );
      if (filtered.length > 0) {
        return (
          <mark
            key={pdfNuberId}
            style={{
              backgroundColor: filtered[0].keyword_color,
              opacity: "0.5",
              color: "transparent",
            }}
          >
            {trimmedPart}
          </mark>
        );
      }

      return <span key={pdfNuberId}>{trimmedPart}</span>;
    });
  }

  customTextRenderer = (textItem: TextItemNew): ReactNode => {
    const { textItems, stringsToHighlight, mValue } = this.state;
    const strValue = textItem.str;
    for (let iterator = 0; iterator < stringsToHighlight.length; iterator++) {
      const stringToHighlight = stringsToHighlight[iterator].name.toLowerCase().trim();
      const escapedWord = stringToHighlight.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
      const matchInTextItem: RegExpMatchArray | null = strValue.toLowerCase().trim().match(escapedWord);

      if (matchInTextItem || mValue === 0) {
        this.setState({ mValue: 1 });
        if (matchInTextItem !== null) {
          return this.highlightPattern(strValue, matchInTextItem[0]);
        }
      }
    }

    return strValue;
  };

  onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    const fileName  = this.state.fileName;
    this.setState({ numPages });

    const documentObject: IChildRefRefArray = {
      numPages,
      fileName,
      canvasRef: this.canvasRef.current!,
      documentRef: document,
    };
    this.handleChildRef(documentObject);
  };

  openFile = async () => {
    const fileResponse = await DocumentPicker.pick({
      presentationStyle: 'fullScreen',
      type: [
        DocumentPicker.types.pdf,
      ],
    });

    if (fileResponse.length > 0) {
      let fileName:string=fileResponse[0].name;
      let fileUri:string=fileResponse[0].uri;

      const formData = new FormData();

      const pdfFileSend:imageType = {
        name: fileName,
        uri: fileUri,
        type: "application/pdf"
      }

      // formData.append("data[documents][]", pdfFileSend);

      this.onUploadFileAPICall(formData);
    }
  };


  btnApplyPDF = () => {
    let webUrl = this.state.webUrl+this.state.token;
    this.setState({
      pdfChooseStatus:true,
      webUrl:webUrl,
    })
  }

  handleTextChangeMobile = (textValue: string) => {
    this.setState({
      keywordText: textValue
    })
  };

  onMessageCall = (event: WebViewMessageEvent) => {

      if(event.nativeEvent.data){
        const pdfValue = event.nativeEvent.data;
        const getUrl = event.nativeEvent.url;
      
        const urlDataCheck = getUrl.split('&pdfdata');
        
        if(urlDataCheck.length == 2){
          const delimiter = "pdf;base64,";
          const resultArray = pdfValue.split(delimiter);
        
          let pdfData = resultArray[1];
          this.saveBlobToFile(pdfData);
        }
      }
    
  }

  saveBlobToFile = async (pdfData:string) => {

    if (Platform.OS !== "web") {
      try {
        const RNFS = require("react-native-fs");
        const filePath = `${RNFS.DownloadDirectoryPath}/updatedFile.pdf`;
        await RNFS.writeFile(filePath, pdfData, 'base64');
        alert("File downloaded successfully, can you check your download folder")
      } catch (error) {
        alert("Something went wrong!");
      }
    }

  };
  
  
  // Customizable Area End
}